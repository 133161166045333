// const slides = document.querySelectorAll(".slide");
// const leftbtn = document.querySelector(".previous");
// const rightbtn = document.querySelector(".next");
// const slider = document.querySelector(".slider");

const slides = document.querySelectorAll(".slide");
const slider = document.querySelector(".slider");
const homeNextArrow = document.querySelector(".home-box-light-Arrow");
const workNextArrow = document.querySelector(".work-light-Arrow");
const aboutNextArrow = document.querySelector(".about-light-Arrow");
const rotate = document.querySelector(".rotate");
const home = document.querySelector(".home");
const shah = document.querySelector(".home-box-shahrukh");
const hand = document.querySelector(".hand__image");

let curSlide = 0;
const maxSlide = slides.length;

const gotToSlide = function (slide) {
  slides.forEach((slides, index) => {
    slides.style.transform = `translateX(${106 * (index - slide)}%)`;
  });
};

gotToSlide(0);

const nextSlide = function () {
  if (curSlide === maxSlide - 1) {
    curSlide = 0;
  } else {
    curSlide++;
  }
  gotToSlide(curSlide);
};

//

let cursl = 0;
const slideLen = slides.length;

function gotwoSlide(mySlide) {
  slides.forEach((slide, index) => {
    slide.style.transform = `translateX(${106 * (index - mySlide)}%)`;
  });
}

let curNum = 0;

home.addEventListener("wheel", function (e) {
  if (e.deltaY > 0) {
    if (curNum <= -320) {
      return;
    } else {
      curNum -= 7;
    }
  }

  if (e.deltaY < 0) {
    if (curNum >= 0) {
      return;
    } else {
      curNum += 7;
    }
  }
  home.style.transform = `translateX(${curNum}%)`;
  hand.style.transform = `translateX(${curNum}%)`;
});

const navbar = document.querySelectorAll(".href");
const logo = document.querySelector(".logo");

navbar[0].addEventListener("click", function () {
  // gotToSlide(0);
  home.style.transform = `translateX(${0}%)`;
  hand.style.transform = `translateX(${0}%)`;
});

navbar[1].addEventListener("click", function () {
  // gotToSlide(1);
  home.style.transform = `translateX(${-105}%)`;
  hand.style.transform = `translateX(${-105}%)`;
});

navbar[2].addEventListener("click", function () {
  // gotToSlide(2);
  home.style.transform = `translateX(${-210}%)`;
  hand.style.transform = `translateX(${-210}%)`;
});

navbar[3].addEventListener("click", function () {
  // gotToSlide(3);
  home.style.transform = `translateX(${-322}%)`;
  hand.style.transform = `translateX(${-322}%)`;
});

logo.addEventListener("click", function () {
  gotToSlide(0);
});

const obsCallBack = function (entries) {
  const [entry] = entries;
  if (entry.isIntersecting) {
  }
};

const obsOptions = {
  root: slider,
  threshold: 0.2,
};

const obs = new IntersectionObserver(obsCallBack, obsOptions);

obs.observe(slides[1]);

//--- SelectedWork Page --- //

// const workType = document.querySelector(".work-type");
const tabs = document.querySelectorAll(".tabs");

// workType.addEventListener("mouseover", function (e) {
//   const id = e.target.dataset.id;

//   if (id) {
//     tabs.forEach((tab) => {
//       tab.classList.remove("active");
//     });

//     e.target.classList.add("active");
//   }
// });

const edit = document.querySelector(".editing");
const packaging = document.querySelector(".packaging");
const editVid = document.querySelectorAll(".edit-video");
const wordD = document.querySelector(".work3d");
const threeD = document.querySelectorAll(".threeD");

// edit.addEventListener("mouseover", function (e) {
//   e.preventDefault();

//   editVid.forEach((eVid) => {
//     eVid.style.height = "100%";
//   });
//   threeD.forEach((thd) => {
//     thd.style.height = "0";
//   });
// });

// packaging.addEventListener("mouseover", function (e) {
//   e.preventDefault();
//   editVid.forEach((eVid) => {
//     eVid.style.height = "0";
//   });

//   threeD.forEach((thd) => {
//     thd.style.height = "0";
//   });
// });

// wordD.addEventListener("mouseover", function (e) {
//   e.preventDefault();
//   threeD.forEach((thd) => {
//     thd.style.height = "100%";
//   });
// });

// edit.addEventListener('mouseout', function(e) {
//   e.preventDefault();
//   editVid.forEach((eVid) => {
//     eVid.style.height = '0';
//   })
// })

//-- work-full display --//

const workFull = document.querySelectorAll(".work-fullDisplay-img");
const workDis = document.querySelectorAll(".work-fullDisplay");
const workBtn = document.querySelector(".workBtn");
const workmycro = document.querySelectorAll(".work-fullDisplay-img-microlyscs");
const workproti = document.querySelectorAll(".work-fullDisplay-img-protiGum");
const worksun = document.querySelectorAll(".work-fullDisplay-img-sunrise");
const workVesta = document.querySelectorAll(".work-fullDisplay-img-vestapet");

let currSlide = 0;
const workSlide = workFull.length;

const workGoToSlide = function (slide) {
  workFull.forEach((slides, index) => {
    slides.style.transform = `translateX(${100 * (index - slide)}%)`;
  });
};

const workGotomycro = function (slide) {
  workmycro.forEach((slides, index) => {
    slides.style.transform = `translateX(${100 * (index - slide)}%)`;
  });
};

const workGoToproti = function (slide) {
  workproti.forEach((slides, index) => {
    slides.style.transform = `translateX(${100 * (index - slide)}%)`;
  });
};

const workGoToSun = function (slide) {
  worksun.forEach((slides, index) => {
    slides.style.transform = `translateX(${100 * (index - slide)}%)`;
  });
};

const workGoToVesta = function (slide) {
  workVesta.forEach((slides, index) => {
    slides.style.transform = `translateX(${100 * (index - slide)}%)`;
  });
};

workGoToVesta(0);

workGoToSun(0);

workGoToproti(0);

workGotomycro(0);

workGoToSlide(0);

function nxtSlide() {
  if (currSlide === workSlide - 1) {
    currSlide = 0;
  } else {
    currSlide++;
  }

  workGoToVesta(curSlide);
  workGoToSlide(currSlide);
  workGotomycro(currSlide);
  workGoToproti(currSlide);
  workGoToSun(curSlide);
}

// workBtn.addEventListener("click", nxtSlide);

workDis.forEach((dis) => {
  dis.addEventListener("wheel", function (e) {
    e.preventDefault();
    e.stopPropagation();
    dis.scrollBy({
      left: e.deltaY < 0 ? -500 : 500,
      behavior: "smooth",
    });
  });
});

// workDis.addEventListener("wheel", function (e) {
//   e.preventDefault();

//   e.stopPropagation();
//   workDis.scrollBy({
//     left: e.deltaY < 0 ? -500 : 500,
//     behavior: "smooth",
//   });
// });

/* Vide-Editing Page */

const clkBtn = document.querySelectorAll(".fas");
const editFull = document.querySelectorAll(".edit-video-full");
const clsBtn = document.querySelector(".clsBtn");
const myVideo = document.querySelectorAll(".myVideo");
const backDrop = document.querySelector(".backDrop");
const editVideo = document.querySelectorAll(".edit-video");
const miniVideo = document.querySelector(".miniVideo");

editVideo.forEach((edit) => {
  edit.addEventListener("mouseover", function (e) {
    const id = e.target.id;
    const minvid = document.getElementById(id);
    e.preventDefault();
    minvid.play();
    minvid.muted = true;
  });
});

editVideo.forEach((edit) => {
  edit.addEventListener("mouseout", function (e) {
    e.preventDefault();
    const id = e.target.id;
    const minvid = document.getElementById(id);
    minvid.pause();
  });
});

editVideo.forEach((cBtn) => {
  cBtn.addEventListener("click", function (e) {
    

    const id = e.target.dataset.id;

    if (id) {
      const vid = document.getElementById(id);
      vid.classList.remove("hide");
      vid.play();
      clsBtn.classList.remove("hide");
      backDrop.classList.remove("hide");
     
    }

    clsBtn.addEventListener("click", function () {
      clsBtn.classList.add("hide");

      myVideo.forEach((myVid) => {
        myVid.classList.add("hide");
        myVid.pause();
      });

      backDrop.classList.add("hide");
    });
  });
});

const imgOverlayText = document.querySelector(".imgOverlay-text");
const workFullDisplay = document.querySelectorAll(".work-fullDisplay");
const workFullDisplayArrow = document.querySelectorAll(
  ".work-fullDisplay-arrow"
);
const imgOverlay = document.querySelectorAll(".imgOverlay");


imgOverlay.forEach((overlay) => {
  overlay.addEventListener("click", function (e) {
    const id = e.target.dataset.id;
    if (id) {
      // workFullDisplay.classList.remove("hide");

      const activeEle = document.getElementById(id);
      activeEle.classList.remove("hide");
    }
  });
});



workFullDisplayArrow.forEach((arr) => {
  arr.addEventListener("click", function (e) {
    e.preventDefault();
    workFullDisplay.forEach((workdis) => {
      workdis.classList.add("hide");
    });
  });
});



/** RESPONSIVE  */

// const mobWorkContainer = document.querySelectorAll(
//   ".section-two__workcontainer"
// );

// const mobWorkPackageBox = document.querySelectorAll(
//   ".section-two__packaging-box"
// );

// const options = {
//   root: null,
//   threshold: 0.1,
// };

// function callback(entries, observer) {
//   entries.forEach((entry) => {
//     if (entry.isIntersecting) {
//       const arr = Array.from(entry.target.children[1].children)
//       entry.target.classList.add("show");
//       arr.forEach((ar) => {
//         ar.classList.add('addskew')
//       })      
//       observer.unobserve(entry.target);
//     } else {
//       entry.target.classList.remove("show");
//     }
//   });
// }

// const observer = new IntersectionObserver(callback, options);

// mobWorkContainer.forEach((container) => {
//   observer.observe(container);
// });

// /** ABOUT-ME-OBSERVER */

// const aboutPara = document.querySelector('.section-three__aboutme-paratwo'); 
// const aboutSpan = document.querySelector('.section-three__aboutme-parataspan'); 

// const aboutOption = {
//   root: null, 
//   threshold: 0.5
// }

// function aboutCall(entries) {
//   entries.forEach((entry) => {
//     if(entry.isIntersecting) {
//       aboutSpan.style.color = '#f93'
//       aboutSpan.style.transition='color 2s ease';
//     } else {
//       aboutSpan.style.color = '#ffffff'
//     } 
//   })
// }


// const aboutObserver = new IntersectionObserver(aboutCall, aboutOption)

// aboutObserver.observe(aboutPara)

/** SCREEN TIMEOUT */

const timeoutscreen = document.querySelector('.time-screen'); 
const timeCount = document.querySelector('.time-screen-count'); 

let time = 0; 
const interval = setInterval(startTime,15); 

function startTime() {
time++
 
  if(time >= 101) return
  timeCount.innerHTML = `${time}%`; 
}


setTimeout(() => {
  timeoutscreen.style.display = 'none'; 
}, 2000)